import React, { useRef, useState, useEffect } from 'react'
import { PinturaEditor } from '@pqina/react-pintura';
import { getEditorDefaults } from '@pqina/pintura';
import imageOne from '../../Assets/img/imageOne.webp';
// Import the editor styles
import '@pqina/pintura/pintura.css';
import '../../../node_modules/@pqina/pintura/pintura.module.css';
import '../../../node_modules/@pqina/pintura/pintura.css';
import '../../Assets/css/imageeditor.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
function ImageEditor() {

  const editorConfig = getEditorDefaults();
  const edit_img = useSelector(state => state.persistedReducer.home.editImage);
  // -----------------------------------------------------------------------------Editor start
  const componentRef = useRef(null);
  const [result, setResult] = useState()
  const [download, setDownload] = useState()
  console.log('edit_img', edit_img);
 useEffect(() => {
   
  const button = document.querySelector('.PinturaButton.PinturaButtonExport');

// Get the value of the 'title' attribute
// const title = button.getAttribute('title');

// Log the title to the console
// console.log(title);
 }, [])
 
  const handleUndo = () => {
    const { editor } = componentRef.current;
    editor.history.undo();
  };
  const downloadFile = (file) => {
    // Create a hidden link and set the URL using createObjectURL
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = URL.createObjectURL(file);
    link.download = file.name;

    // We need to add the link to the DOM for "click()" to work
    document.body.appendChild(link);
    link.click();

    // To make this work on Firefox we need to wait a short moment before clean up
    setTimeout(() => {
      URL.revokeObjectURL(link.href);
      link.parentNode.removeChild(link);
    }, 0);
  };
  const handleEditorProcess = (imageState) => {
    console.log("==", imageState)
    setDownload(imageState.dest)
    setResult(URL.createObjectURL(imageState.dest))
    // downloadFile(imageState.dest);
  };
  // -----------------------------------------------------------------------------Editor end
  return (
    <div className='container'>
      <div className="imageeditor" style={{ height: '700px', filter: "blur(0px)" }}>
        <PinturaEditor
          ref={componentRef}
          {...editorConfig}
          src={edit_img}
          onProcess={(res) => downloadFile(res?.dest)}
          imageCropAspectRatio={1}
        ></PinturaEditor>
        {/* {result && <img src={result} alt="" />} */}
        {console.log("==", result)}
        {/* <button onClick={() => downloadFile(download)} >Download</button> */}
      </div>
    </div>
  )
}

export default ImageEditor
