import React from 'react'
import { Container } from 'react-bootstrap'
import logo from '../../Assets/img/logo.png'
const index = () => {
    return (
        <section className='maintenancePage'>
            <Container className=''>
                <div className="what-is-up">
                    <img alt='logo' src={logo} width={300} className='m-auto mb-5'/>
                    <div className="spinny-cogs">
                        <i className="fa fa-cog" aria-hidden="true"></i>
                        <i className="fa fa-5x fa-cog fa-spin" aria-hidden="true"></i>
                        <i className="fa fa-3x fa-cog" aria-hidden="true"></i>
                    </div>
                    <h1 className="maintenance">Under Maintenance</h1>
                    <h2>Our developers are hard at work updating your system. Please wait while we do this. We have also made the spinning cogs to distract you.</h2>
                    <a href='mailto:info@dall-efree.com'>info@dall-efree.com</a>
                </div>
            </Container>
        </section>
    )
}

export default index